<template>
  <div :key="info.num" v-if="info.content">
    <div
      class="box"
      :style="{
        marginTop: info.content.styles.marginTop + 'px',
        backgroundColor: info.content.styles.backGround,
        borderRadius: info.content.styles.borderRadius ? '10px' : '0',
        padding: `0 ${info.content.styles.paddingLorR}px`,
      }"
    >
      <div class="new_box">
        <img class="new_icon" :src="info.content.img" />
        <div class="new_line"></div>
        <div class="new_title">
          <el-carousel
            class="new"
            :indicator-position="'none'"
            direction="vertical"
            autoplay
            :style="{
              width: `${250 - info.content.styles.paddingLorR * 2}px`,
            }"
          >
            <el-carousel-item
              v-for="(item, index) in info.content.list"
              :key="index"
            >
              <h3
                class="swiper-item"
                :style="{
                  textAlign: info.content.styles.textPosition,
                  color: info.content.styles.textColor,
                }"
              >
                {{ item.title }}
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <img class="new_more" :src="info_more" />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info_more: require("@/assets/images/info_more.png"),
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
.box {
  box-sizing: border-box;
}
.new_box {
  height: 40px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  // padding: 0 30rpx;
  .new_icon {
    width: 29px;
    height: 14px;
  }
  .new_line {
    width: 1px;
    height: 26px;
    background: #dfeaf2;
    margin: 0 12px;
  }
  .new_more {
    width: 26px;
    height: 26px;
  }
}
.new {
  height: 40px;
  width: 250px;
  // background-color: #647089;
  // background-color: #fff;
  // border-radius: 12rpx;
  .swiper-item {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #647089;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>