<template>
    <div
      :key="info.num"
      v-if="info.content"
    >
    <div :style="{'backgroundColor':info.content.styles.backGround,'height':info.content.styles.height+'px'}" class="blank"></div>
    </div>
  </template>
  
  <script> 
  export default {
    name: "Blank",
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      num: {
        type: null,
      },
    },
    data() {
      return {
        info: {},
        actIndex: 0,
      };
    },
    computed: {
      pageInfo() {
        return this.$store.state.pagesCustom.pageInfo;
      },
    },
    watch: {
      // 监听计算属性，这样当someData变化时，会触发watcher
      pageInfo: {
        handler(nval, oval) {
          this.info = this.$store.state.pagesCustom.pageInfo.filter(
            (ele) => ele.num == this.num
          )[0];
          this.$forceUpdate();
        },
        deep: true, // 深度监听
      },
    },
    created() {},
    methods: {
      change(e) {
        this.actIndex = e;
      },
      // forceUpdateFun(){
      //   console.log('12123')
      // }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  </style>