<template>
  <div
    :key="info.num"
    v-if="info.content.list"
  >
   <div class="bar" >
      <div class="item" v-if="info.content.list[0].select">
        <img :src="info.content.list[0].select" />
        <div style="color:#E93423">首页</div>
      </div>
      <div class="item" v-if="info.content.list[0].select">
        <img :src="info.content.list[1].unchecked" />
        <div>分类</div>
      </div>
      <div class="item" v-if="info.content.list[0].select">
        <img :src="info.content.list[2].unchecked" />
        <div>购物车</div>
      </div>
      <div class="item" v-if="info.content.list[0].select">
        <img :src="info.content.list[3].unchecked" />
        <div >我的</div>
      </div>
      
   </div>
  </div>
</template> 

<script>
export default {
  name: "Icon",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {
        content: {
        list: [
          {
            img: "",
            title: "",
            value: "",
          },
        ],
        styles: {
          borderRadius:true,//圆角
          indicator:'circular',//circular圆 line线 none无 
          indicatorPosition:'left',//left居左 right居右 center居中
          isShowBackGround:true,
          backGroundStart:'#fff',
          backGroundEnd:'#fff',
          paddingLorR:10,
          marginTop:0
        },
      }, 
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      console.log('1231')
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.imgBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.img {
  width: 100%;
  height: 100%;
}
.block {
  position: relative;
  overflow: hidden;
}
.dio {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: 10000;
}
.yuan {
  width: 7px;
  height: 7px;
  background-color: #c0c4cc;
  border-radius: 50%;
  margin: 0 2px;
}
.yuanAct {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 2px;
}
.bar{
  width: 100%;
  height: 80px;
  display: flex;
  padding-top: 8px;
  box-sizing: border-box;
  position: relative;
  z-index: 100000000;
}
.item{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  img{
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
  }
}
</style>