var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{key:_vm.info.num},[_c('div',{staticClass:"box",style:({
      marginTop: _vm.info.content.styles.marginTop + 'px',
      backgroundColor: _vm.info.content.styles.backGround,
      borderRadius: _vm.info.content.styles.borderRadius ? '10px' : '0',
      padding: ("0 " + (_vm.info.content.styles.paddingLorR) + "px"),
    })},[_c('div',{staticClass:"new_box"},[_c('img',{staticClass:"new_icon",attrs:{"src":_vm.info.content.img}}),_c('div',{staticClass:"new_line"}),_c('div',{staticClass:"new_title"},[_c('el-carousel',{staticClass:"new",style:({
            width: ((250 - _vm.info.content.styles.paddingLorR * 2) + "px"),
          }),attrs:{"indicator-position":'none',"direction":"vertical","autoplay":""}},_vm._l((_vm.info.content.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('h3',{staticClass:"swiper-item",style:({
                textAlign: _vm.info.content.styles.textPosition,
                color: _vm.info.content.styles.textColor,
              })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1)],1),_c('img',{staticClass:"new_more",attrs:{"src":_vm.info_more}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }