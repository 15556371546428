var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{staticClass:"line_box",style:({
    marginTop: _vm.info.content.styles.marginTop + 'px',
    backgroundColor: _vm.info.content.styles.backGround,
    borderRadius: _vm.info.content.styles.bgBorderRadius ? '8px' : '0',
    padding: ("0 " + (_vm.info.content.styles.paddingLorR) + "px"),
  })},[(_vm.info.content.styles.showType == 'one')?_c('div',[_c('div',{},[_c('div',{staticClass:"type_one"},_vm._l((_vm.info.content.list.slice(0, 5)),function(item,index){return _c('div',{key:index,staticClass:"type_one_item"},[_c('img',{staticClass:"type_one_icon",style:({
              borderRadius:
                _vm.info.content.styles.iconStyles == 'round' ? '50%' : '0',
            }),attrs:{"src":item.img,"mode":""}}),_c('div',{staticClass:"title",style:({
              color: _vm.info.content.styles.fontColor,
            })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])]):_c('div',{staticClass:"multiLine"},_vm._l((_vm.info.content.list),function(item,index){return _c('div',{key:index,staticClass:"multiLine_item",style:({ width: 100 / _vm.info.content.styles.num + '%' })},[_c('div',{staticClass:"multiLine_con"},[_c('img',{staticClass:"type_one_icon",style:({
            borderRadius:
              _vm.info.content.styles.iconStyles == 'round' ? '50%' : '0',
          }),attrs:{"src":item.img,"mode":""}}),_c('div',{staticClass:"title",style:({
            color: _vm.info.content.styles.fontColor,
          })},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }