var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{key:_vm.info.num},[_c('div',{staticClass:"nav_box",style:({
      marginTop: _vm.info.content.styles.marginTop + 'px',
      paddingLeft: _vm.info.content.styles.paddingL + 'px',
      background:_vm.info.content.styles.backGround
    })},[_c('div',{staticClass:"home_nav"},[_c('div',{staticClass:"logo storeText"},[_vm._v(_vm._s(_vm.info.content.title))]),_c('div',{staticClass:"searchBox",style:({
          textAlign:_vm.info.content.styles.textPosition,
          borderRadius:
          _vm.info.content.styles.borderRadius ||
          _vm.info.content.styles.borderRadius == undefined
              ? '36px'
              : '0px',
        })},[_vm._v(" 商品 ")])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }