<template>
  <div
    class="activity"
    v-if="info.content"
    :style="{
      marginTop: info.content.styles.marginTop + 'px',
      padding: `0 ${info.content.styles.paddingLorR}px`,
      backgroundColor: info.content.styles.backGround,
      borderRadius: info.content.styles.backgroundRadius ? '10px' : '0',
    }"
  >
    <div class="activity_top">
      <div class="activity_title">活动专区</div>
      <div class="activity_more">进去逛逛</div>
    </div>
    <div class="activity_list">
      <div
        class="activity_item"
        v-for="(item, index) in info.content.list"
        :key="index"
      >
        <img
          :src="item.img"
          :style="{
            borderRadius: info.content.styles.contentRadius ? '10px' : '0',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {
        content: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
          styles: {
            borderRadius: true, //圆角
            indicator: "circular", //circular圆 line线 none无
            indicatorPosition: "left", //left居左 right居右 center居中
            isShowBackGround: true,
            backGroundStart: "#fff",
            backGroundEnd: "#fff",
            paddingLorR: 10,
            marginTop: 0,
          },
        },
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.activity {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 8px;
  // padding: 0 30rpx;
  padding: 0 10px;
  box-sizing: border-box;
  // padding-bottom: 30rpx;
}
.activity_top {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .activity_title {
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #2c3041;
    line-height: 20px;
  }
  .activity_more {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #abb0c0;
    line-height: 20px;
  }
}
.activity_list {
  .activity_item {
    width: 100%;
    height: 137px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
</style>