<template>
  <div
    :style="{
      marginTop: info.content.styles.marginTop + 'px',
      backgroundColor: info.content.styles.backGround,
      borderRadius: info.content.styles.bgBorderRadius ? '8px' : '0',
      padding: `0 ${info.content.styles.paddingLorR}px`,
    }"
    class="line_box"
    v-if="info.content"
  >
    <!-- 单行 -->
    <div v-if="info.content.styles.showType == 'one'">
      <!-- <div v-if="info.content.list.length > 5" class="type_one_scroll">
        <div
          class="type_one_item"
          v-for="(item, index) in info.content.list"
          :key="index"
        >
          <img
            :style="{
              borderRadius:
                info.content.styles.iconStyles == 'round' ? '50%' : 'none',
            }"
            class="type_one_icon"
            :src="item.img"
            mode=""
          />
          <div class="title">{{ item.title }}</div>
        </div>
      </div> -->
      <div class="">
        <div class="type_one">
          <div
            class="type_one_item"
            v-for="(item, index) in info.content.list.slice(0, 5)"
            :key="index"
          >
            <img
              :style="{
                borderRadius:
                  info.content.styles.iconStyles == 'round' ? '50%' : '0',
              }"
              class="type_one_icon"
              :src="item.img"
              mode=""
            />
            <div
              class="title"
              :style="{
                color: info.content.styles.fontColor,
              }"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多行 -->
    <div v-else class="multiLine">
      <div
        :style="{ width: 100 / info.content.styles.num + '%' }"
        class="multiLine_item"
        v-for="(item, index) in info.content.list"
        :key="index"
      >
        <div class="multiLine_con">
          <img
            :style="{
              borderRadius:
                info.content.styles.iconStyles == 'round' ? '50%' : '0',
            }"
            class="type_one_icon"
            :src="item.img"
            mode=""
          />
          <div
            class="title"
            :style="{
              color: info.content.styles.fontColor,
            }"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {
        content: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
          styles: {
            borderRadius: true, //圆角
            indicator: "circular", //circular圆 line线 none无
            indicatorPosition: "left", //left居左 right居右 center居中
            isShowBackGround: true,
            backGroundStart: "#fff",
            backGroundEnd: "#fff",
            paddingLorR: 10,
            marginTop: 0,
          },
        },
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {

        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.line_box {
  padding-top: 10px !important;
  box-sizing: border-box;
}
.type_one {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // justify-content: space-between;
}
.type_one_scroll {
  white-space: nowrap;
  padding-left: 12.5px;
  overflow: hidden;
  .type_one_item {
    margin-right: 30px;
  }
}
.type_one_item {
  // width: 88rpx;
  text-align: center;
  display: inline-block;
  .type_one_icon {
    width: 44px;
    height: 44px;
    display: block;
    font-size: 0;
  }
  .title {
    // width: 88rpx;
    margin-top: 6px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #2c3041;
    line-height: 17px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.multiLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .multiLine_item {
    margin-bottom: 10px;
    // height: 100rpx;
    .multiLine_con {
      width: 44px;
      margin: 0 auto;
    }
    // background-color: #2C3041;
    // margin: 10rpx;
  }
}
.type_one_icon {
  width: 44px;
  height: 44px;
  display: block;
  font-size: 0;
}
.title {
  margin-top: 6px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #2c3041;
  line-height: 17px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>