<template>
  <div :key="info.num" v-if="info.content" class="box">
    <img mode="aspectFill" class="banner_bj" :src="info.content.swiper.list[actIndex].img" />
    <!-- <p class="titBox">组合组件</p> -->
    <div class="nav_box">
      <div class="home_nav">
        <div  class="logo storeText"> 
          {{ info.content.search.title}}
        </div>
        <!-- <div @click="storeChange" class="logo_name">{{storeName}}</div> -->
        <div class="searchBox">商品</div>
      </div>
    </div>

    <div
      :key="info.num"
    >
      <div style="position: relative">
        <el-carousel height="150px" indicator-position="none" @change="change">
          <el-carousel-item
            v-for="(item, index) in info.content.swiper.list"
            :key="index"
          >
            <div class="imgBox">
              <img
                class="img"
                :src="item.img"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
        <div
          class="dio"
        >
          <div
            v-for="(item, index) in info.content.swiper.list"
            :key="index"
            :class="actIndex == index ? 'yuanAct' : 'yuan'"
          ></div>
        </div>
      </div>
    </div>

    <!-- <div class="tabs" v-if="info.content.tab.list">
      <div
        class="tab"
        v-for="(item, index) in info.content.tab.list"
        :key="index"
      >
        {{ item.title }}
      </div>
    </div> -->
  </div>
</template>
  
  <script>
export default {
  name: "MakeUpComponent",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        console.log('123',this.info)
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
  .box{
    padding: 15px 20px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
.nav_box {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  // background-color: #333333;
  /* background-color: #FFFFFF; */
}
.nav_box_isSticky {
  background-color: #ffffff;
  color: #333333;
  transition: all 1s;
}

.home_nav {
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  .logo_name {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 14px;
    // color: #FFFFFF;
  }
  .logo {
    width: 68px;
    height: 21px;
  }
}

.searchBox {
  width: 180px;
  margin-left: 15px;
  line-height: 34px;
  padding: 0 25px 0 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
}
.storeText {
  color: #343f55;
  font-size: 16px;
}
.imgBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.img {
  width: 100%;
  height: 100%;
}
.block {
  position: relative;
  overflow: hidden;
}
.dio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: 10000;
}
.yuan {
  width: 7px;
  height: 7px;
  background-color: #c0c4cc;
  border-radius: 50%;
  margin: 0 2px;
}
.yuanAct {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 2px;
}
.banner_bj {
		width: 100%;
		height: 100%;
		filter: blur(15px);
		transform: scale(1.5);
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
	}
  .tabs {
    display: flex;
    position: relative;
    z-index: 11;
    margin-top: 15px;
  .tab {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    min-height: 20px;
    min-width: 0px;
    color: #2c3041;
    line-height: 20px;
    margin-right: 25px;
  }
}
.storeText{
  color: #343F55;
  font-size: 16px;
	line-height: 20px;
  width: 82px;
  font-size: 12px;
  overflow: hidden;
text-overflow: ellipsis;
word-break: break-all;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
</style>