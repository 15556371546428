<template>
  <div
    class="box"
    v-if="info.content"
    :style="{
      backgroundColor: info.content.styles.backGround,
      marginTop: info.content.styles.marginTop + 'px',
      fontSize: info.content.styles.fontSize + 'px',
      color: info.content.styles.fontColor,
      textAlign: info.content.styles.textPosition,
      fontStyle: info.content.styles.textStyles,
      fontWeight: info.content.styles.textStyles,
      borderRadius: info.content.styles.borderRadius ? '8px' : '0',
      padding: `0 ${info.content.styles.paddingLorR}px`,
    }"
  >
    {{ info.content.title }}
  </div>
</template>
  
  <script>
export default {
  name: "Title",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
.box {
  box-sizing: border-box;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>