var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{key:_vm.info.num},[_c('div',{staticClass:"goods",style:({
      backgroundColor: _vm.info.content.styles.backGroundColor,
      borderRadius: _vm.info.content.styles.backgroundRadius ? '10px' : '0',
      marginTop: _vm.info.content.styles.marginTop + 'px',
    })},[(_vm.info.content.styles.showType == 'two')?_c('div',{staticClass:"goods_list",style:({
        backgroundColor: _vm.info.content.styles.backGroundColor,
      })},_vm._l((_vm.goodList),function(item,index){return _c('div',{key:index,staticClass:"goods_item",style:({
          backgroundColor: _vm.info.content.styles.backGroundColor,
        }),on:{"click":function($event){return _vm.toDteails(item.id)}}},[(item.isPreSale == 1)?_c('div',{staticClass:"presale"},[_vm._v("预售")]):_vm._e(),_c('img',{staticClass:"goods_cover",style:({
            borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
          }),attrs:{"src":item.mainImg,"mode":"aspectFill"}}),_c('div',{staticClass:"goods_info"},[_c('div',{staticClass:"goods_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"discount"},[(item.discountShow)?_c('span',[_vm._v(_vm._s((item.discountRatio * 10).toFixed(1))+"折")]):_vm._e(),_vm._v("已售"+_vm._s(item.saleNum > 999 ? "999+" : item.saleNum)+" ")]),_c('div',{staticClass:"price_box"},[_c('div',{staticClass:"price",style:({
                color: _vm.info.content.styles.priceColor,
              })},[_c('span',[_vm._v("￥")]),_vm._v(_vm._s(Number(item.salePrice).toFixed(2))),(item.discountShow != 0)?_c('span',{staticClass:"price_box"},[_vm._v(_vm._s(item.storeName))]):_vm._e()])])])])}),0):_c('div',{staticClass:"goods_list_row",style:({
        backgroundColor: _vm.info.content.styles.backGroundColor,
      })},_vm._l((_vm.goodList),function(item,index){return _c('div',{key:index,staticClass:"goods_item",style:({
          backgroundColor: _vm.info.content.styles.backGroundColor,
        }),on:{"click":function($event){return _vm.toDteails(item.id)}}},[(item.isPreSale == 1)?_c('div',{staticClass:"presale"},[_vm._v("预售")]):_vm._e(),_c('img',{staticClass:"goods_cover",style:({
            borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
          }),attrs:{"src":item.mainImg,"mode":"aspectFill"}}),_c('div',{staticClass:"goods_info"},[_c('div',{staticClass:"goods_name row_name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"discount"},[(item.discountShow)?_c('span',[_vm._v(_vm._s((item.discountRatio * 10).toFixed(1))+"折")]):_vm._e(),_vm._v("已售"+_vm._s(item.saleNum > 999 ? "999+" : item.saleNum)+" ")]),_c('div',{staticClass:"price_box"},[_c('div',{staticClass:"price",style:({
                color: _vm.info.content.styles.priceColor,
              })},[_c('span',[_vm._v("￥")]),_vm._v(_vm._s(_vm.getPrice(item.salePrice))),(item.discountShow != 0)?_c('span',{staticClass:"price_box"},[_vm._v(_vm._s(_vm.getVipName(item.storeName)))]):_vm._e()])])])])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }