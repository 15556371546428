<template>
  <div
    v-if="info.content"
    :style="{
      marginTop: info.content.styles.marginTop + 'px',
      padding: `0 ${info.content.styles.paddingLorR}px`,
      background:info.content.styles.backGround,
      borderRadius: info.content.styles.borderRadius ? '10px' : '0',
    }"
    class="box"
  >
    <div v-if="info.content.stylesType == 1" class="">
      <img class="type_one" :src="info.content.list[0].img" mode="widthFix" />
    </div>
    <div v-if="info.content.stylesType == 2" class="type_two">
      <img
        v-for="(item, index) in info.content.list"
        :src="item.img"
        :key="index" 
        mode="aspectFill"
      />
    </div>
    <div v-if="info.content.stylesType == 3" class="type_three">
      <img
        v-for="(item, index) in info.content.list"
        :src="item.img"
        :key="index"
        mode="aspectFill"
      />
    </div>
    <div v-if="info.content.stylesType == 4" class="type_four">
      <img
        class="image_left"
        :src="info.content.list[0].img"
        mode="aspectFill"
      />
      <div class="image_right">
        <img :src="info.content.list[1].img" mode="aspectFill" />
        <img :src="info.content.list[2].img" mode="aspectFill" />
      </div>
    </div>
    <div v-if="info.content.stylesType == 5" class="type_five">
      <img
        v-for="(item, index) in info.content.list"
        :src="item.img"
        :key="index"
        mode="aspectFill"
      />
    </div>
    <div v-if="info.content.stylesType == 6" class="type_six">
      <img
        v-for="(item, index) in info.content.list"
        :src="item.img"
        :key="index"
        mode="aspectFill"
      />
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "Images",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
    
<style lang="scss" scoped>
.box{
    box-sizing: border-box;
}
.type_one {
  width: 100%;
  height:375px
}
.type_two {
  display: flex;
  align-items: center;
  img {
    width: 50%;
    height: 187.5px;
  }
}
.type_three {
  display: flex;
  align-items: center;
  img {
    width: 33.33%;
    height: 125px;
  }
}
.type_four {
  display: flex;
  align-items: center;
  .image_left {
    width: 45%;
    height: 168px;
  }
  .image_right {
    width: 55%;
    img {
      width: 100%;
      height: 84px;
    }
  }
}
.type_five {
  display: flex;
  align-items: center;
  img {
    width: 25%;
    height: 94px;
  }
}
.type_six {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50%;
    height: 187.5px;
  }
}
img {
  font-size: 0px;
  display: block;
}
</style>