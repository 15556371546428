<template>
  <div
    v-if="info.content"
    :style="{
      marginTop: info.content.styles.marginTop + 'px',
      padding: `0 ${info.content.styles.paddingLorR}px`,
    }"
    class="line_box"
  >
    <div
      :style="{
        borderBottom: `${info.content.styles.lineStyles} ${info.content.styles.height+'px'} ${info.content.styles.lineColor}`,
      }"
      class="line"
    ></div>
  </div>
</template>
  
  <script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
.line_box {
  .line {
    width: 100%;
  }
}
</style>