<template>
  <div
    v-if="info.content"
    :style="{
      marginTop: info.content.styles.marginTop + 'px',
      backgroundColor: info.content.styles.backGround,
    }"
    class="recommend"
  >
    <div v-if="info.content.styles.showType == 'one'" class="recommend_one">
      <div
        v-for="(item, index) in info.content.list"
        class="recommend_one_item"
        :key="index"
      >
        <div class="title">{{ item.title }}</div>
        <div class="introduction">{{ item.introduction }}</div>
        <img class="icon" :src="item.img" mode="aspectFill" />
      </div>
    </div>
    <div v-else class="recommend_multiLine">
      <div
        class="recommend_multiLine_item"
        v-for="(item, index) in info.content.list"
        :key="index"
      >
        <img class="multiLine_icon" :src="item.img" mode="aspectFill" />
        <div class="multiLine_righ">
          <div class="title">{{ item.title }}</div>
          <div class="introduction">{{ item.introduction }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {
        content: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
          styles: {
            borderRadius: true, //圆角
            indicator: "circular", //circular圆 line线 none无
            indicatorPosition: "left", //left居左 right居右 center居中
            isShowBackGround: true,
            backGroundStart: "#fff",
            backGroundEnd: "#fff",
            paddingLorR: 10,
            marginTop: 0,
          },
        },
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        console.log("1221");
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  padding: 0 15px;
}
.recommend_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  box-sizing: border-box;
  .recommend_one_item {
    // width: 20%;
    padding: 12px 5px 6px 5px;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
    .title {
      width: 72px;
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 13px;
      color: #2c3041;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .introduction { 
      width: 72px;
      margin-top: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 11px;
      color: #abb0c0;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon {
      margin-top: 6px;
      width: 72px;
      height: 72px;
      border-radius: 6px;
    }
    // background-color: #333;
  }
}
.recommend_multiLine {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 7px;
  box-sizing: border-box;
  .recommend_multiLine_item {
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 6px;
    margin: 0 3px;
    margin-bottom: 7px;
    .multiLine_icon {
      width: 48px;
      height: 48px;
      border-radius: 6px;
    }
    .multiLine_righ {
      margin-left: 8px;
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        font-size: 15px;
        color: #2c3041;
        line-height: 40rpx;
        overflow: hidden;
text-overflow: ellipsis;
word-break: break-all;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
      }
      .introduction {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #abb0c0;
        line-height: 16px;
        overflow: hidden;
text-overflow: ellipsis;
word-break: break-all;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
      }
    }
  }
}
</style>