var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{key:_vm.info.num,style:({
     backgroundImage:_vm.info.content.styles.isShowBackGround?("linear-gradient(90deg, " + (_vm.info.content.styles.backGroundStart) + " 0%, " + (_vm.info.content.styles.backGroundEnd) + " 100%)"):'',
     padding: ((_vm.info.content.styles.marginTop) + "px  " + (_vm.info.content.styles.paddingLorR) + "px"),
   })},[_c('div',{staticStyle:{"position":"relative"}},[_c('el-carousel',{attrs:{"height":"150px","indicator-position":"none"},on:{"change":_vm.change}},_vm._l((_vm.info.content.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"imgBox"},[_c('img',{staticClass:"img",style:(_vm.info.content.styles.borderRadius ? { borderRadius: '10px' } : {}),attrs:{"src":item.img}})])])}),1),(_vm.info.content && _vm.info.content.styles.indicator !== 'none')?_c('div',{staticClass:"dio",style:(_vm.info.content.styles.indicatorPosition == 'left'
         ? { justifyContent: 'left' }
         : _vm.info.content.styles.indicatorPosition == 'right'
         ? { justifyContent: 'right' }
         : { justifyContent: 'center' })},_vm._l((_vm.info.content.list),function(item,index){return _c('div',{key:index,class:_vm.actIndex == index ? 'yuanAct' : 'yuan',style:(_vm.info.content.styles.indicator == 'line'
           ? { width: '12px', height: '2px', borderRadius: '1px' }
           : {})})}),0):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }