<template>
  <div :key="info.num" v-if="info.content">
    <div
      :style="{
        marginTop: info.content.styles.marginTop + 'px',
        paddingLeft: info.content.styles.paddingL + 'px',
        background:info.content.styles.backGround
      }"
      class="nav_box"
    >
      <div
        class="home_nav"

      >
        <!-- <img v-if="info.content.img" class="logo"  :src="info.content.img" /> -->
        <div  class="logo storeText">{{info.content.title}}</div>
        <!-- <div @click="storeChange" class="logo_name">{{storeName}}</div> -->
        <div
          :style="{
            textAlign:info.content.styles.textPosition,
            borderRadius:
            info.content.styles.borderRadius ||
            info.content.styles.borderRadius == undefined
                ? '36px'
                : '0px',
          }"
          class="searchBox"
        >
        商品
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {},
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>
  
  <style lang="scss" scoped>
.nav_box {
	width: 100%;
	padding: 0 15px;
  box-sizing: border-box;
	// background-color: #333333;
	/* background-color: #FFFFFF; */
}
.nav_box_isSticky{
	background-color: #FFFFFF;
	color: #333333;
	transition: all 1s;
}

.home_nav {
	width: 100%;
	display: flex;
  height: 50px;
	align-items: center;
	.logo_name{
		font-family: PingFang SC, PingFang SC;
		font-weight: bold;
		font-size: 14px;
		// color: #FFFFFF;
	}
	.logo{
		height: 21px;
    object-fit: cover;
	}
}

.searchBox {
	width: 180px;
	margin-left: 15px;
	line-height: 34px;
	padding: 0 25px 0 15px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 18px;
	color: #ffffff;
	font-size: 14px;
	position: relative;
	box-sizing: border-box;
	color: rgba(255,255,255,.7);
}
.storeText{
  color: #343F55;
  font-size: 16px;
	line-height: 20px;
  width: 82px;
  font-size: 12px;
  overflow: hidden;
text-overflow: ellipsis;
word-break: break-all;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
</style>