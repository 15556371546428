var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{staticClass:"box",style:({
    backgroundColor: _vm.info.content.styles.backGround,
    marginTop: _vm.info.content.styles.marginTop + 'px',
    fontSize: _vm.info.content.styles.fontSize + 'px',
    color: _vm.info.content.styles.fontColor,
    textAlign: _vm.info.content.styles.textPosition,
    fontStyle: _vm.info.content.styles.textStyles,
    fontWeight: _vm.info.content.styles.textStyles,
    borderRadius: _vm.info.content.styles.borderRadius ? '8px' : '0',
    padding: ("0 " + (_vm.info.content.styles.paddingLorR) + "px"),
  })},[_vm._v(" "+_vm._s(_vm.info.content.title)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }