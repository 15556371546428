<template>
  <div
    class="presale"
    v-if="info.content && list"
    :style="{
      borderRadius: info.content.styles.backgroundRadius ? '10px' : '0',
      marginTop: info.content.styles.marginTop + 'px',
    }"
  >
    <div
      class="presale_top"
      :style="{
        borderRadius: info.content.styles.backgroundRadius ? '10px' : '0',
      }"
    >
      <div class="presale_title">预售专区</div>
      <div class="presale_more">进去逛逛</div>
    </div>
    <div
      :style="{
        backgroundColor: info.content.styles.themeColor,
      }"
    >
      <div
        v-if="info.content.styles.stylesType == 'templates'"
        class="templates"
      >
        <div
          class="templates_first"
          :style="{
            borderRadius: info.content.styles.contentRadius ? '10px' : '0',
          }"
        >
          <div class="name">{{ list[0].name }}</div>
          <div class="price" v-if="list[0]">
            <span>￥</span>
            {{ list[0].salePrice }}
            <span> 预售价</span>
          </div>
          <div class="cover_box" v-if="list[0]">
            <img class="cover" :src="list[0].mainImg" mode="aspectFill" />
            <div class="botton">去预定</div>
          </div>
        </div>
        <div class="templates_first_list" v-if="list.length >= 2">
          <div
            class="templates_item"
            v-if="list[1]"
            :style="{
              borderRadius: info.content.styles.contentRadius ? '10px' : '0',
            }"
          >
            <img class="cover" :src="list[1].mainImg" />
            <div class="templates_item_con">
              <div class="name">{{ list[1].name }}</div>
              <div class="price">
                <span>￥</span>
                {{ list[1].salePrice }}
                <span> 预售价</span>
              </div>
              <div class="botton">去预定</div>
            </div>
          </div>
          <div
            class="templates_item"
            v-if="list.length >= 3"
            :style="{
              borderRadius: info.content.styles.contentRadius ? '10px' : '0',
            }"
          >
            <template v-if="list[2]">
              <img class="cover" :src="list[2].mainImg" />
              <div class="templates_item_con">
                <div class="name">1231</div>
                <div class="price">
                  <span>￥</span>
                  {{ list[2].salePrice }}
                  <span> 预售价</span>
                </div>
                <div class="botton">去预定</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="presale_list">
        <template v-if="info.content.styles.stylesType == 'one'">
          <div v-for="(item, index) in list.slice(0, 3)" :key="index" class="presale_item">
            <img class="cover" :src="item.mainImg" mode="aspectFill" />
            <div class="price">
              <span>￥</span>
              {{ item.salePrice }}
              <span> 预售价</span>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in list" :key="index" class="presale_item">
            <img class="cover" :src="item.mainImg" mode="aspectFill" />
            <div class="price">
              <span>￥</span>
              {{ item.salePrice }}
              <span> 预售价</span>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsPresale } from "@/api/decorate/decorate";
export default {
  name: "Icon",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      list: [],
      info: {
        content: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
          styles: {
            borderRadius: true, //圆角
            indicator: "circular", //circular圆 line线 none无
            indicatorPosition: "left", //left居左 right居右 center居中
            isShowBackGround: true,
            backGroundStart: "#fff",
            backGroundEnd: "#fff",
            paddingLorR: 10,
            marginTop: 0,
          },
        },
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    getGoodsList(num, sort) {
      goodsPresale({
        pageNum: 1,
        pageSize: 6,
        storeId: this.$store.state.user.userInfo.storeId,
      })
        .then((res) => {
          this.list = res.data.records;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.presale {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 8px;
  // padding: 0 30rpx;
  background-color: #fff;
  padding-bottom: 15px;

  .presale_top {
    width: 100%;
    height: 42px;
    background: linear-gradient(
      180deg,
      rgba(233, 52, 35, 0.36) 0%,
      rgba(233, 52, 35, 0) 100%
    );
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .presale_title {
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #2c3041;
      line-height: 20px;
    }
    .presale_more {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #abb0c0;
      line-height: 20px;
    }
  }
  .presale_list {
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    .presale_item {
      width: 102px;
      margin: 0 4px;
      .cover {
        width: 102px;
        height: 102px;
        border-radius: 6px;
      }
      .price {
        margin-top: 6px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        font-size: 17px;
        color: #e93423;
        line-height: 14px;
        span {
          font-size: 10px;
          font-weight: 400;
        }
      }
      .name {
        margin-top: 6px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #2c3041;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .templates {
    padding: 0 10px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    .templates_first {
      width: 126px;
      height: 171px;
      background: linear-gradient(
        180deg,
        rgba(233, 52, 35, 0.36) 0%,
        rgba(233, 52, 35, 0.13) 100%
      );
      border-radius: 6px;
      padding: 10px;
      box-sizing: border-box;
    }
    .templates_first_list {
      .templates_item {
        width: 194px;
        height: 86px;
        background: linear-gradient(
          180deg,
          rgba(233, 52, 35, 0.36) 0%,
          rgba(233, 52, 35, 0.13) 100%
        );
        border-radius: 6px;
        padding: 10px 8px 10px 10px;
        display: flex;
        box-sizing: border-box;
        .cover {
          width: 66px;
          height: 66px;
          border-radius: 6px;
          margin-right: 10px;
          margin-top: 0;
        }
        .templates_item_con {
          width: 100px;
          .botton {
            margin-top: 5px;
            margin-left: 45px;
            // display: flex;
            // justify-content: flex-end;
            width: 62px;
            height: 24px;
            background: #e93423;
            border-radius: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
    .name {
      // margin-top: 12rpx;
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 12px;
      color: #2c3041;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .price {
      margin-top: 6px;
      font-family: PingFangSC, PingFang SC;
      font-weight: bold;
      font-size: 17px;
      color: #e93423;
      line-height: 14px;
      span {
        font-size: 10px;
        font-weight: 400;
      }
    }
    .cover_box {
      position: relative;
      width: 106px;
      height: 106px;
      margin-top: 6px;
      .cover {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .botton {
        position: absolute;
        bottom: 6px;
        left: 22px;
        width: 62px;
        height: 24px;
        background: #e93423;
        border-radius: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
</style>