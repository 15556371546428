<template>
  <div :key="info.num" v-if="info.content">
    <div
      class="goods"
      :style="{
        backgroundColor: info.content.styles.backGroundColor,
        borderRadius: info.content.styles.backgroundRadius ? '10px' : '0',
        marginTop: info.content.styles.marginTop + 'px',
      }"
    >
      <div
        class="goods_list"
        v-if="info.content.styles.showType == 'two'"
        :style="{
          backgroundColor: info.content.styles.backGroundColor,
        }"
      >
        <div
          @click="toDteails(item.id)"
          v-for="(item, index) in goodList"
          :key="index"
          class="goods_item"
          :style="{
            backgroundColor: info.content.styles.backGroundColor,
          }"
        >
          <div v-if="item.isPreSale == 1" class="presale">预售</div>
          <img
            class="goods_cover"
            :src="item.mainImg"
            mode="aspectFill"
            :style="{
              borderRadius: info.content.styles.contentRadius ? '10px' : '0',
            }"
          />
          <div class="goods_info">
            <div class="goods_name">{{ item.name }}</div>
            <div class="discount">
              <span v-if="item.discountShow"
                >{{ (item.discountRatio * 10).toFixed(1) }}折</span
              >已售{{ item.saleNum > 999 ? "999+" : item.saleNum }}
            </div>
            <div class="price_box">
              <div
                class="price"
                :style="{
                  color: info.content.styles.priceColor,
                }"
              >
                <span>￥</span>{{ Number(item.salePrice).toFixed(2)
                }}<span v-if="item.discountShow != 0" class="price_box">{{
                  item.storeName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="goods_list_row"
        :style="{
          backgroundColor: info.content.styles.backGroundColor,
        }"
      >
        <div
          @click="toDteails(item.id)"
          v-for="(item, index) in goodList"
          :key="index"
          class="goods_item"
          :style="{
            backgroundColor: info.content.styles.backGroundColor,
          }"
        >
          <div v-if="item.isPreSale == 1" class="presale">预售</div>
          <img
            class="goods_cover"
            :src="item.mainImg"
            mode="aspectFill"
            :style="{
              borderRadius: info.content.styles.contentRadius ? '10px' : '0',
            }"
          />
          <div class="goods_info">
            <div class="goods_name row_name">{{ item.name }}</div>
            <div class="discount">
              <span v-if="item.discountShow"
                >{{ (item.discountRatio * 10).toFixed(1) }}折</span
              >已售{{ item.saleNum > 999 ? "999+" : item.saleNum }}
            </div>
            <div class="price_box">
              <div
                class="price"
                :style="{
                  color: info.content.styles.priceColor,
                }"
              >
                <span>￥</span>{{ getPrice(item.salePrice)
                }}<span v-if="item.discountShow != 0" class="price_box">{{
                  getVipName(item.storeName)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recommendPageList } from "@/api/decorate/decorate";
import { getPrice, getVipName } from "@/utils/other";
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      getPrice,
      getVipName,
      goodList: [],
      info: {
        content: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
          styles: {
            borderRadius: true, //圆角
            indicator: "circular", //circular圆 line线 none无
            indicatorPosition: "left", //left居左 right居右 center居中
            isShowBackGround: true,
            backGroundStart: "#fff",
            backGroundEnd: "#fff",
            paddingLorR: 10,
            marginTop: 0,
          },
        },
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        if (this.info.content.num) {
            if(this.info.content.sort=="custom"){
              setTimeout(()=>{
                this.goodList = this.info.content.list;
              },1000)
              this.goodList = this.info.content.list;
            }else{
              this.getGoodsList(this.info.content.num, this.info.content.sort);
            }
          // console.log('this.info',this.info)
  
        }
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  methods: {
    getGoodsList(num, sort) {  
      if (sort != 'custom') {
        recommendPageList({
          pageNum: 1,
          pageSize: num,
          storeId: this.$store.state.user.userInfo.storeId,
        })
          .then((res) => {
            this.goodList = res.data.records;
            if (sort == "sales") {
              this.goodList.sort((a, b) => b.saleNum - a.saleNum);
            }
            if (sort == "price") {
              this.goodList.sort((a, b) => b.salePrice - a.salePrice);
            }
            this.$forceUpdate();
          })
          .catch((res) => {});
      }
    },
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.goods {
  padding: 10px;
}

.goods_list {
  padding-top: 10px;
  width: 100%;
  // background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 5px;
  .goods_item {
    position: relative;
    width: 172px;
    // padding: 0 20rpx;
    // margin-bottom: 30rpx;
    background-color: #ffffff;
    border-radius: 12px;
    padding-bottom: 10px;
    .delete_icon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 29px;
      height: 29px;
    }
    .presale {
      width: 34px;
      height: 20px;
      line-height: 20px;
      span-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e93323;
      font-size: 10px;
      color: #ffffff;
    }
    .goods_cover {
      width: 100%;
      height: 180px;
      border-radius: 5px;
    }
    .goods_info {
      // padding: 0 20rpx;
    }
    .goods_name {
      margin-top: 5px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      color: #474747;
      font-weight: bold;
      overflow: hidden;
      span-overflow: ellipsis;
      white-space: nowrap;
    }
    .discount {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #858585;
      margin-top: 2.5px;
      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #e93323;
        margin-right: 4px;
      }
    }
    .price_box {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        display: flex;
        align-items: baseline;
        font-weight: bold;
        color: #e93323;
        font-size: 16px;
        span {
          font-size: 10px;
        }
        .price_box {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .car {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.goods_list_row {
  .goods_item {
    position: relative;
    // padding: 20rpx;
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    .presale {
      width: 34px;
      height: 20px;
      line-height: 20px;
      span-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e93323;
      font-size: 10px;
      color: #ffffff;
    }
    .goods_cover {
      width: 88px;
      height: 88px;
      border-radius: 10px;
    }
    .goods_info {
      margin-left: 10px;
      width: 245px;
      .goods_name {
        // margin-top: 10rpx;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        color: #474747;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        span-overflow: ellipsis;
      }
      .discount {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #858585;
        margin-top: 2.5px;
        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #e93323;
          margin-right: 4px;
        }
      }
      .price_box {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          display: flex;
          align-items: baseline;
          font-weight: bold;
          color: #e93323;
          font-size: 16px;
          span {
            font-size: 10px;
          }
          .price_box {
            font-size: 10px;
            margin-left: 5px;
          }
        }
        .car {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>