<template>
    <div
	class="richtext_box"
	:style="{'backgroundColor': info.content.styles.backGround,
	'marginTop': info.content.styles.marginTop+'px',
	'padding':`10px ${info.content.styles.paddingLorR}px`}">
		<!-- <uParse :content="content||''"></uParse> -->
    <div v-html=" info.content.value"></div>
	</div> 
  </template>
  
  <script>
  export default {
    name: "Swiper",
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      num: {
        type: null,
      },
    },
    data() {
      return {
        info: {},
        actIndex: 0,
      };
    },
    computed: {
      pageInfo() {
        return this.$store.state.pagesCustom.pageInfo;
      },
    },
    watch: {
      // 监听计算属性，这样当someData变化时，会触发watcher
      pageInfo: {
        handler(nval, oval) {
          this.info = this.$store.state.pagesCustom.pageInfo.filter(
            (ele) => ele.num == this.num
          )[0];
          this.$forceUpdate();
        },
        deep: true, // 深度监听
      },
    },
    created() {},
    methods: {
      change(e) {
        this.actIndex = e;
      },
      // forceUpdateFun(){
      //   console.log('12123')
      // }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .imgBox {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .img {
    width: 100%;
    height: 100%;
  }
  .block {
    position: relative;
    overflow: hidden;
  }
  .dio {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 7px;
    left: 0;
    z-index: 10000;
  }
  .yuan {
    width: 7px;
    height: 7px;
    background-color: #c0c4cc;
    border-radius: 50%;
    margin: 0 2px;
  }
  .yuanAct {
    width: 7px;
    height: 7px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 2px;
  }
  </style>