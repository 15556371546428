var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content && _vm.list)?_c('div',{staticClass:"presale",style:({
    borderRadius: _vm.info.content.styles.backgroundRadius ? '10px' : '0',
    marginTop: _vm.info.content.styles.marginTop + 'px',
  })},[_c('div',{staticClass:"presale_top",style:({
      borderRadius: _vm.info.content.styles.backgroundRadius ? '10px' : '0',
    })},[_c('div',{staticClass:"presale_title"},[_vm._v("预售专区")]),_c('div',{staticClass:"presale_more"},[_vm._v("进去逛逛")])]),_c('div',{style:({
      backgroundColor: _vm.info.content.styles.themeColor,
    })},[(_vm.info.content.styles.stylesType == 'templates')?_c('div',{staticClass:"templates"},[_c('div',{staticClass:"templates_first",style:({
          borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
        })},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.list[0].name))]),(_vm.list[0])?_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥")]),_vm._v(" "+_vm._s(_vm.list[0].salePrice)+" "),_c('span',[_vm._v(" 预售价")])]):_vm._e(),(_vm.list[0])?_c('div',{staticClass:"cover_box"},[_c('img',{staticClass:"cover",attrs:{"src":_vm.list[0].mainImg,"mode":"aspectFill"}}),_c('div',{staticClass:"botton"},[_vm._v("去预定")])]):_vm._e()]),(_vm.list.length >= 2)?_c('div',{staticClass:"templates_first_list"},[(_vm.list[1])?_c('div',{staticClass:"templates_item",style:({
            borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
          })},[_c('img',{staticClass:"cover",attrs:{"src":_vm.list[1].mainImg}}),_c('div',{staticClass:"templates_item_con"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.list[1].name))]),_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥")]),_vm._v(" "+_vm._s(_vm.list[1].salePrice)+" "),_c('span',[_vm._v(" 预售价")])]),_c('div',{staticClass:"botton"},[_vm._v("去预定")])])]):_vm._e(),(_vm.list.length >= 3)?_c('div',{staticClass:"templates_item",style:({
            borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
          })},[(_vm.list[2])?[_c('img',{staticClass:"cover",attrs:{"src":_vm.list[2].mainImg}}),_c('div',{staticClass:"templates_item_con"},[_c('div',{staticClass:"name"},[_vm._v("1231")]),_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥")]),_vm._v(" "+_vm._s(_vm.list[2].salePrice)+" "),_c('span',[_vm._v(" 预售价")])]),_c('div',{staticClass:"botton"},[_vm._v("去预定")])])]:_vm._e()],2):_vm._e()]):_vm._e()]):_c('div',{staticClass:"presale_list"},[(_vm.info.content.styles.stylesType == 'one')?_vm._l((_vm.list.slice(0, 3)),function(item,index){return _c('div',{key:index,staticClass:"presale_item"},[_c('img',{staticClass:"cover",attrs:{"src":item.mainImg,"mode":"aspectFill"}}),_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥")]),_vm._v(" "+_vm._s(item.salePrice)+" "),_c('span',[_vm._v(" 预售价")])]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])}):_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"presale_item"},[_c('img',{staticClass:"cover",attrs:{"src":item.mainImg,"mode":"aspectFill"}}),_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥")]),_vm._v(" "+_vm._s(item.salePrice)+" "),_c('span',[_vm._v(" 预售价")])]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])})],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }