import { get, put,Delete,post } from '../axios'

//页面装修-配置页面-新增
export const configAdd = params => post(`/system/web/decorate/page/config/add`,params);

// 页面装修-配置页面-列表
export const getConfigList= params => get(`/system/web/decorate/page/config/list`,params);

// 页面装修-配置页面-设为首页
export const homeUpdate= params => put(`/system/web/decorate/page/config/home/update`,params);

// 页面装修-配置页面-列表
export const configDelete = params => Delete(`/system/web/decorate/page/config/delete/${params}`);

// 页面装修-配置页面-复制
export const configCopy = params => get(`/system/web/decorate/page/config/copy/${params}`);
// 页面装修-配置页面-复制
export const getConfigView = params => get(`/system/web/decorate/page/config/view/${params}`);

// 页面装修-配置页面-编辑
export const configUp = params => put(`/system/web/decorate/page/config/update`,params);

// 保存商品自定义装修
export const decorationTemplate = params => post(`/goods/web/goods/save/decoration/template`,params);

// 获取商品自定义装修
export const getDecorationTemplate = params => get(`/goods/web/goods/decoration/template`,params);


// 文章-专题-列表
export const articleList = params => get(`/system/web/decorate/article/topic/list`,params);
// 文章-专题-新增
export const articleAdd = params => post(`/system/web/decorate/article/topic/add`,params);
// 文章-专题-删除
export const articleDel = params => Delete(`/system/web/decorate/article/topic/delete/${params}`);
// 文章-专题-详情
export const articleView= params => get(`/system/web/decorate/article/topic/view/${params}`);
// 文章-专题-编辑
export const articleUpdate= params => put(`/system/web/decorate/article/topic/update`,params);
// 文章-专题-编辑
export const articleStateUp= params => put(`/system/web/decorate/article/topic/state/update`,params);
//页面装修-配置页面-选择链接-文章专题-列表
export const linkarticleStateUp= params => get(`/system/web/decorate/page/config/link/article/list`,params);

//个人中心-配置页面-编辑
export const indexConfigUpdate= params => put(`/system/web/decorate/index/config/update`,params);
//个人中心-配置页面-详情-预览
export const indexConfigView= params => get(`/system/web/decorate/index/config/view`,params);

//获取首页推荐列表
export const recommendPageList= params => get(`/goods/web/goods/goods/recommend`,params);


//商品预售列表
export const goodsPresale= params => get(`/goods/web/goods/goods/preSale`,params);





