var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info.content)?_c('div',{staticClass:"activity",style:({
    marginTop: _vm.info.content.styles.marginTop + 'px',
    padding: ("0 " + (_vm.info.content.styles.paddingLorR) + "px"),
    backgroundColor: _vm.info.content.styles.backGround,
    borderRadius: _vm.info.content.styles.backgroundRadius ? '10px' : '0',
  })},[_vm._m(0),_c('div',{staticClass:"activity_list"},_vm._l((_vm.info.content.list),function(item,index){return _c('div',{key:index,staticClass:"activity_item"},[_c('img',{style:({
          borderRadius: _vm.info.content.styles.contentRadius ? '10px' : '0',
        }),attrs:{"src":item.img}})])}),0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity_top"},[_c('div',{staticClass:"activity_title"},[_vm._v("活动专区")]),_c('div',{staticClass:"activity_more"},[_vm._v("进去逛逛")])])}]

export { render, staticRenderFns }