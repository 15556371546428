<template>
  <div
  v-if="info.content"
    :key="info.num"
    :style="{
      backgroundImage:info.content.styles.isShowBackGround?`linear-gradient(90deg, ${info.content.styles.backGroundStart} 0%, ${info.content.styles.backGroundEnd} 100%)`:'',
      padding: `${info.content.styles.marginTop}px  ${info.content.styles.paddingLorR}px`,
    }"
  >
  <div style="position: relative;">
    <el-carousel
      height="150px"
      indicator-position="none"
      @change="change"
    >
      <el-carousel-item v-for="(item, index) in info.content.list" :key="index">
        <div
          class="imgBox"
        >
          <img
            class="img"
            :style="
              info.content.styles.borderRadius ? { borderRadius: '10px' } : {}
            "
            :src="item.img"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div
      class="dio"
      v-if="info.content && info.content.styles.indicator !== 'none'"
      :style="
        info.content.styles.indicatorPosition == 'left'
          ? { justifyContent: 'left' }
          : info.content.styles.indicatorPosition == 'right'
          ? { justifyContent: 'right' }
          : { justifyContent: 'center' }
      "
    >
      <div
        v-for="(item, index) in info.content.list"
        :key="index"
        :style="
          info.content.styles.indicator == 'line'
            ? { width: '12px', height: '2px', borderRadius: '1px' }
            : {}
        "
        :class="actIndex == index ? 'yuanAct' : 'yuan'"
      ></div>
    </div>
  </div>
 </div> 
</template>

<script>
export default {
  name: "Swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    num: {
      type: null,
    },
  },
  data() {
    return {
      info: {
        content: {
        list: [
          {
            img: "",
            title: "",
            value: "",
          },
        ],
        styles: {
          borderRadius:true,//圆角
          indicator:'circular',//circular圆 line线 none无 
          indicatorPosition:'left',//left居左 right居右 center居中
          isShowBackGround:true,
          backGroundStart:'#fff',
          backGroundEnd:'#fff',
          paddingLorR:10,
          marginTop:0
        },
      }, 
      },
      actIndex: 0,
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.info = this.$store.state.pagesCustom.pageInfo.filter(
          (ele) => ele.num == this.num
        )[0];
        this.$forceUpdate();
      },
      deep: true, // 深度监听
    },
  },
  created() {},
  methods: {
    change(e) {
      this.actIndex = e;
    },
    // forceUpdateFun(){
    //   console.log('12123')
    // }
  },
};
</script>

<style lang="scss" scoped>
.imgBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block {
  position: relative;
  overflow: hidden;
}
.dio {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: 10000;
}
.yuan {
  width: 7px;
  height: 7px;
  background-color: #c0c4cc;
  border-radius: 50%;
  margin: 0 2px;
}
.yuanAct {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 2px;
}
</style>